"use client";

import { useTheme } from "next-themes";
import { MoonLoader } from "react-spinners";

export default function PageLoader() {
  const { theme = "dark" } = useTheme();

  return (
    <div className="fixed top-0 left-0 w-full h-[calc(100dvh_-_66px)] flex items-center justify-center">
      <div className="p-8 rounded-3xl bg-muted shadow-lg z-10">
        <MoonLoader size={16} color={theme === "dark" ? "white" : "black"} />
      </div>
    </div>
  );
}
